<template>
  <div class="privacypreferencesview">

    <form id="privacyPreferencesForm" autocomplete="off">

      <br>
      <b-container class="dns">
        <b-row>
          <b-col class="col-12 col-lg-4" align-self="center">
            <input type="checkbox" id="doNotSell" name="doNotSell" v-model="pc.doNotSell">&nbsp;
            <label for="doNotSell">Do Not Sell My Personal Information</label>
          </b-col>
          <b-col>
            If you select this box and leave it checked, your information will be permanently deleted 
            from Semcasting's database within 10 to 30 days. However, if you prefer more fine-grained control 
            over the use of your personal information, leave this unchecked and select from the options below.
          </b-col>
        </b-row>
      </b-container>


      <br>
      <p class="text-gray" style="text-align:left">
        Select from the following tables the data categories, variables, media channels or shopping 
        advertisers that you would like to allow, block, or permit if your data is averaged with 
        others in the region. 
      </p>
      <p>
        <!-- <button class="btn btn-primary" @click="showProfile">See a demographics profile for your zip code</button> -->
        <b><router-link :to="{ name: 'ProfileRoute' }">See a demographic profile for your zip code</router-link></b>
      </p>

      <div id="categoryUsage">
        <div class="tabular-grid d-sm-table w-100 mb-5">
          <div class="row d-sm-table-row sticky-top d-none">
            <div class="p-3 d-sm-table-cell" style="width:1%">
              Categories of Data
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Allow Data Use from this Category
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Block Usage of My Data in Category
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Allow if Averaged to Zip Code Value
            </div>
          </div>

          <div class="d-sm-table-row">
            <div class="p-3 d-sm-table-cell" style="width:1%">
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllCategoriesAllow" @click.stop="setAllCategories('allow')" class="d-none d-lg-block all">All</a></small>
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllCategoriesBlock" @click.stop="setAllCategories('block')" class="d-none d-lg-block all">All</a></small>
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllCategoriesAveraged" @click.stop="setAllCategories('averaged')" class="d-none d-lg-block all">All</a></small>
            </div>
          </div>

          <DemoRadioComponent title="Household Demographics" name="uHouseholdDemographics" :doNotSell="pc.doNotSell" v-model="pc.uHouseholdDemographics" />
          <DemoRadioComponent title="Household Affluence" name="uHouseholdAffluence" :doNotSell="pc.doNotSell" v-model="pc.uHouseholdAffluence" />
          <DemoRadioComponent title="Residence Information" name="uResidenceInformation" :doNotSell="pc.doNotSell" v-model="pc.uResidenceInformation" />
          <DemoRadioComponent title="Vehicle Information" name="uVehicleInformation" :doNotSell="pc.doNotSell" v-model="pc.uVehicleInformation" />
          <DemoRadioComponent title="Political Information" name="uPoliticalInformation" :doNotSell="pc.doNotSell" v-model="pc.uPoliticalInformation" />
          <DemoRadioComponent title="Media Preference" name="uMediaInformation" :doNotSell="pc.doNotSell" v-model="pc.uMediaInformation" />
          <DemoRadioComponent title="Shopper Preferences" name="uShopperPreferences" :doNotSell="pc.doNotSell" v-model="pc.uShopperPreferences" />

          <div class="row d-sm-table-row sticky-top d-none">
            <div class="p-3 d-sm-table-cell" style="width:1%">
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
            </div>
          </div>

          <div class="row d-sm-table-row sticky-top d-none">
            <div class="p-3 d-sm-table-cell" style="width:1%">
              Demographic Fields
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Allow to be used in advertising and personalization of content
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Block Usage of Selected data
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              Allow when Averaged with Other Regional Values
            </div>
          </div>

          <div class="d-sm-table-row">
            <div class="p-3 d-sm-table-cell" style="width:1%">
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllDemographicsAllow" @click.stop="setAllDemographics('allow')" class="d-none d-lg-block all">All</a></small>
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllDemographicsBlock" @click.stop="setAllDemographics('block')" class="d-none d-lg-block all">All</a></small>
            </div>
            <div class="p-3 text-sm-center d-sm-table-cell" style="width:10%">
              <small><a id="setAllDemographicsAveraged" @click.stop="setAllDemographics('averaged')" class="d-none d-lg-block all">All</a></small>
            </div>
          </div>

          <DemoRadioComponent title="Number of Adults in Household" name="numAdults" :doNotSell="pc.doNotSell" v-model="pc.uNumAdults" />
          <DemoRadioComponent title="Adult Age Ranges in Household" name="ageRange" :doNotSell="pc.doNotSell" v-model="pc.uAgeRange" />
          <DemoRadioComponent title="Presence of Children" name="presenceOfChildren" :doNotSell="pc.doNotSell" v-model="pc.uPresenceOfChildren" />
          <DemoRadioComponent title="Estimated Household Income Range" name="incomeRange" :doNotSell="pc.doNotSell" v-model="pc.uIncome" />
          <DemoRadioComponent title="Estimated Household Discretionary Income Range" :doNotSell="pc.doNotSell" name="discIncomeRange" v-model="pc.uDiscIncome" />
          <DemoRadioComponent title="Estimated Home Value Range" name="homeValueRange" :doNotSell="pc.doNotSell" v-model="pc.uHomeValue" />
          <DemoRadioComponent title="Length of Residence Range" name="lengthOfResidenceRange" :doNotSell="pc.doNotSell" v-model="pc.uLengthOfResidence" />
          <DemoRadioComponent title="Vehicle Propensity" name="vehiclePropensity" :doNotSell="pc.doNotSell" v-model="pc.uVehiclePropensity" />
          <DemoRadioComponent title="Voter Registration" name="voterRegistration" :doNotSell="pc.doNotSell" v-model="pc.uVoterRegistration" />
        </div>

        <p class="text-gray" style="text-align:left">
          Select any Categories of Product or Service Advertising that you would like us to block:
          <small><a id="setAllIndustriesTrue" @click.stop="setAllIndustries(true)" class="d-none d-lg-block all">All</a></small>
          <small><a id="setAllIndustriesFalse" @click.stop="setAllIndustries(false)" class="d-none d-lg-block all">None</a></small>                              
        </p>

        <b-container class="usage">
          <b-row>
            <b-col class="col-12 col-lg-4">
              <input type="checkbox" id="artsAndEntertainment" name="selectedIndustries" v-model="pc.cArtsAndEntertainment" :disabled=pc.doNotSell>&nbsp;
              <label for="artsAndEntertainment">Arts &amp; Entertainment</label><br>
              
              <input type="checkbox" id="automotive" name="selectedIndustries" v-model="pc.cAutomotive" :disabled=pc.doNotSell>&nbsp;
              <label for="automotive">Automotive</label><br>
              
              <input type="checkbox" id="business" name="selectedIndustries" v-model="pc.cBusiness" :disabled=pc.doNotSell>&nbsp;
              <label for="business">Business</label><br>
              
              <input type="checkbox" id="careers" name="selectedIndustries" v-model="pc.cCareers" :disabled=pc.doNotSell>&nbsp;
              <label for="careers">Careers</label><br>
              
              <input type="checkbox" id="education" name="selectedIndustries" v-model="pc.cEducation" :disabled=pc.doNotSell>&nbsp;
              <label for="education">Education</label><br>

              <input type="checkbox" id="familyAndParenting" name="selectedIndustries" v-model="pc.cFamilyAndParenting" :disabled=pc.doNotSell>&nbsp;
              <label for="familyAndParenting">Family &amp; Parenting</label><br>
          </b-col>
          <b-col class="col-12 col-lg-4">
              <input type="checkbox" id="foodAndDrink" name="selectedIndustries" v-model="pc.cFoodAndDrink" :disabled=pc.doNotSell>&nbsp;
              <label for="foodAndDrink">Food &amp; Drink</label><br>
              
              <input type="checkbox" id="healthAndFitness" name="selectedIndustries" v-model="pc.cHealthAndFitness" :disabled=pc.doNotSell>&nbsp;
              <label for="healthAndFitness">Health &amp; Fitness</label><br>
              
              <input type="checkbox" id="homeAndGarden" name="selectedIndustries" v-model="pc.cHomeAndGarden" :disabled=pc.doNotSell>&nbsp;
              <label for="homeAndGarden">Home &amp; Garden</label><br>
              
              <input type="checkbox" id="lawGovernmentAndPolitics" name="selectedIndustries" v-model="pc.cLawGovernmentAndPolitics" :disabled=pc.doNotSell>&nbsp;
              <label for="lawGovernmentAndPolitics">Law, Government &amp; Politics</label><br>
              
              <input type="checkbox" id="news" name="selectedIndustries" v-model="pc.cNews" :disabled=pc.doNotSell>&nbsp;
              <label for="news">News</label><br>
              
              <input type="checkbox" id="personalFinance" name="selectedIndustries" v-model="pc.cPersonalFinance" :disabled=pc.doNotSell>&nbsp;
              <label for="personalFinance">Personal Finance</label><br>
          </b-col>
          <b-col class="col-12 col-lg-4">                      
              <input type="checkbox" id="science" name="selectedIndustries" v-model="pc.cScience" :disabled=pc.doNotSell>&nbsp;
              <label for="science">Science</label><br>
              
              <input type="checkbox" id="society" name="selectedIndustries" v-model="pc.cSociety" :disabled=pc.doNotSell>&nbsp;
              <label for="society">Society</label><br>
              
              <input type="checkbox" id="sports" name="selectedIndustries" v-model="pc.cSports" :disabled=pc.doNotSell>&nbsp;
              <label for="sports">Sports</label><br>
              
              <input type="checkbox" id="technologyAndComputing" name="selectedIndustries" v-model="pc.cTechnologyAndComputing" :disabled=pc.doNotSell>&nbsp;
              <label for="technologyAndComputing">Technology &amp; Computing</label><br>
              
              <input type="checkbox" id="travel" name="selectedIndustries" v-model="pc.cTravel" :disabled=pc.doNotSell>&nbsp;
              <label for="travel">Travel</label><br>
            </b-col>
          </b-row>
        </b-container>

        <br>
        <p class="text-gray" style="text-align:left">
          Select any Shopping Advertising Category that you would like us to block:
          <small><a id="setAllShoppingAdvertisingCategoriesTrue" @click.stop="setAllShoppingAdvertisingCategories(true)" class="d-none d-lg-block all">All</a></small>
          <small><a id="setAllShoppingAdvertisingCategoriesFalse" @click.stop="setAllShoppingAdvertisingCategories(false)" class="d-none d-lg-block all">None</a></small>                              
        </p>
        <div id="industries">
          <b-container class="usage">
            <b-row>
              <b-col class="col-12 col-lg-4">
                <input type="checkbox" id="artsAndCraftsStores" name="selectedShoppinAdvertisingCategories" v-model="pc.cArtsAndCraftsStores">&nbsp;
                <label for="artsAndCraftsStores">Arts &amp; Crafts Stores</label><br>

                <input type="checkbox" id="bigBoxShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cBigBoxShoppers">&nbsp;
                <label for="bigBoxShoppers">Big Box Shoppers</label><br>

                <input type="checkbox" id="retailVitaminShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cRetailVitaminShoppers">&nbsp;
                <label for="retailVitaminShoppers">Retail Vitamin Shoppers</label><br>

                <input type="checkbox" id="coffeeChainConsumers" name="selectedShoppingAdvertisingCategories" v-model="pc.cCoffeeChainConsumers">&nbsp;
                <label for="coffeeChainConsumers">Coffee Chain Consumers</label><br>

                <input type="checkbox" id="electronicsStoreShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cElectronicsStoreShoppers">&nbsp;
                <label for="electronicsStoreShoppers">Electronics Store Shoppers</label><br>

                <input type="checkbox" id="familyRestaurantCustomers" name="selectedShoppingAdvertisingCategories" v-model="pc.cFamilyRestaurantCustomers">&nbsp;
                <label for="familyRestaurantCustomers">Family Restaurant Cust.</label><br>
              </b-col>
              <b-col class="col-12 col-lg-4">
                <input type="checkbox" id="fastFoodCustomers" name="selectedShoppingAdvertisingCategories" v-model="pc.cFastFoodCustomers">&nbsp;
                <label for="fastFoodCustomers">Fast Food Customers</label><br>

                <input type="checkbox" id="groceryChainShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cGroceryChainShoppers">&nbsp;
                <label for="groceryChainShoppers">Grocery Chain Shoppers</label><br>

                <input type="checkbox" id="homeImprovementStores" name="selectedShoppingAdvertisingCategories" v-model="pc.cHomeImprovementStores">&nbsp;
                <label for="homeImprovementStores">Home Improvement Stores</label><br>

                <input type="checkbox" id="kitchenAndBathShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cKitchenAndBathShoppers">&nbsp;
                <label for="kitchenAndBathShoppers">Kitchen and Bath Shoppers</label><br>

                <input type="checkbox" id="retailClothingStores" name="selectedShoppingAdvertisingCategories" v-model="pc.cRetailClothingStores">&nbsp;
                <label for="retailClothingStores">Retail Clothing Stores</label><br>

                <input type="checkbox" id="officeSupplyStores" name="selectedShoppingAdvertisingCategories" v-model="pc.cOfficeSupplyStores">&nbsp;
                <label for="officeSupplyStores">Office Supply Stores</label><br>
              </b-col>
              <b-col class="col-12 col-lg-4">                
                <input type="checkbox" id="retailFitnessCenterClients" name="selectedShoppingAdvertisingCategories" v-model="pc.cRetailFitnessCenterClients">&nbsp;
                <label for="retailFitnessCenterClients">Ret. Fitness Center Clients</label><br>

                <input type="checkbox" id="retailPetStoreShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cRetailPetStoreShoppers">&nbsp;
                <label for="retailPetStoreShoppers">Retail Pet Store Shoppers</label><br>

                <input type="checkbox" id="rxChainShoppers" name="selectedShoppingAdvertisingCategories" v-model="pc.cRxChainShoppers">&nbsp;
                <label for="rxChainShoppers">Rx Chain Shoppers</label><br>

                <input type="checkbox" id="sportingGoodsStores" name="selectedShoppingAdvertisingCategories" v-model="pc.cSportingGoodsStores">&nbsp;
                <label for="sportingGoodsStores">Sporting Goods Stores</label><br>

                <input type="checkbox" id="sportsAttendees" name="selectedShoppingAdvertisingCategories" v-model="pc.cSportsAttendees">&nbsp;
                <label for="sportsAttendees">Sports Attendees</label><br>

                <input type="checkbox" id="telecomProviders" name="selectedShoppingAdvertisingCategories" v-model="pc.cTelecomProviders">&nbsp;
                <label for="telecomProviders">Telecom Providers</label><br>
              </b-col>
            </b-row>
          </b-container>
        </div>        
      </div>
    </form>
    <br>
    <p align="center">
      <button class="btn btn-primary" @click="savePrivacyPreferences">Save</button>
    </p>
    <br>
  </div>
  
</template>


<script>
  /* eslint-disable no-debugger */
  /* eslint-disable no-console */
  import { pcMixin } from '@/mixins/pcMixin';
  import { apiMixin } from '@/mixins/apiMixin.js';

  import DemoRadioComponent from '@/components/DemoRadioComponent';

  export default {
    name: 'PrivacyPreferencesView',

    mixins: [ pcMixin, apiMixin ],

    components: {
      DemoRadioComponent,
    },

    methods: {
      showProfile() {
        debugger;
        this.$router.push({ name: 'ProfileRoute' });
      },

      setAllCategories(usage) {
        if (!this.pc.doNotSell) {
          this.pc.uHouseholdDemographics = usage;
          this.pc.uHouseholdAffluence = usage;
          this.pc.uResidenceInformation = usage;
          this.pc.uVehicleInformation = usage;
          this.pc.uPoliticalInformation = usage;
          this.pc.uMediaInformation = usage;
          this.pc.uShopperPreferences = usage;
        }
      },

      setAllDemographics(usage) {
        if (!this.pc.doNotSell) {
          this.pc.uNumAdults = usage;
          this.pc.uAgeRange = usage;
          this.pc.uPresenceOfChildren = usage;
          this.pc.uIncome = usage;
          this.pc.uDiscIncome = usage;
          this.pc.uHomeValue = usage;
          this.pc.uLengthOfResidence = usage;
          this.pc.uVehiclePropensity = usage;
          this.pc.uVoterRegistration = usage;
        }
      },

      setAllIndustries(checked) {
        if (!this.pc.doNotSell) {
          this.pc.cArtsAndEntertainment = checked;
          this.pc.cAutomotive = checked;
          this.pc.cBusiness = checked;
          this.pc.cCareers = checked;
          this.pc.cEducation = checked;
          this.pc.cFamilyAndParenting = checked;
          this.pc.cFoodAndDrink = checked;
          this.pc.cHealthAndFitness = checked;
          this.pc.cHomeAndGarden = checked;
          this.pc.cLawGovernmentAndPolitics = checked;
          this.pc.cNews = checked;
          this.pc.cPersonalFinance = checked;
          this.pc.cScience = checked;
          this.pc.cSociety = checked;
          this.pc.cSports = checked;
          this.pc.cTechnologyAndComputing = checked;
          this.pc.cTravel = checked;
        }
      },

      setAllShoppingAdvertisingCategories(checked) {
        if (!this.pc.doNotSell) {
          this.pc.cArtsAndCraftsStores = checked;
          this.pc.cBigBoxShoppers = checked;
          this.pc.cRetailVitaminShoppers = checked;
          this.pc.cCoffeeChainConsumers = checked;
          this.pc.cElectronicsStoreShoppers = checked;
          this.pc.cFamilyRestaurantCustomers = checked;
          this.pc.cFastFoodCustomers = checked;
          this.pc.cGroceryChainShoppers = checked;
          this.pc.cHomeImprovementStores = checked;
          this.pc.cKitchenAndBathShoppers = checked;
          this.pc.cRetailClothingStores = checked;
          this.pc.cOfficeSupplyStores = checked;
          this.pc.cRetailFitnessCenterClients = checked;
          this.pc.cRetailPetStoreShoppers = checked;
          this.pc.cRxChainShoppers = checked;
          this.pc.cSportingGoodsStores = checked;
          this.pc.cSportsAttendees = checked;
          this.pc.cTelecomProviders = checked;
        }
      },

      savePrivacyPreferences() {
        this.pcSpinnerShow();
        setTimeout(() => {
          this.pcSpinnerHide();

          this.pcModal("Your changes have been saved")
          .then(() => {
            this.apiSavePrivacyPreferences()
            .then(() => {})
            .catch((error) => {
              this.handleError(error)
            })
            .finally(() => {})
          })
          .finally(() => {})
        }, 2000);
      }
    }
  }
</script>

<style scoped>
  .dns {
    background-color:lightgray;
    text-align: left;
    min-width: 100%;
  }
  .usage {
    background-color:lightgray;
    text-align: left;
    min-width: 100%;
  }
  a.all {
    color: blue;
    text-decoration: underline;
  }
</style>

