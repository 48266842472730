<template>
  <div class="row d-sm-table-row d-block mb-0">
    <div class="p-sm-3 py-0 px-3 text-sm-right font-weight-bold text-nowrap d-sm-table-cell">
      {{ title }}
    </div>

    <div class="p-sm-3 py-0 pr-3 text-sm-center text-nowrap d-sm-table-cell">
      <div class="custom-radio">
        <input type="radio" :name="name" value="allow" :checked="value == 'allow'" :disabled=doNotSell @input="$emit('input', $event.target.value)">
        &nbsp;<label for="allow" class="d-sm-none" :disabled=doNotSell>Allow</label>
      </div>
    </div>
    <div class="p-sm-3 py-0 pr-3 text-sm-center text-nowrap d-sm-table-cell">
      <div class="custom-radio">
        <input type="radio" :name="name" value="block" :checked="value == 'block'" :disabled=doNotSell @input="$emit('input', $event.target.value)">
        &nbsp;<label for="allow" class="d-sm-none" :disabled=doNotSell>Block</label>
      </div>
    </div>
    <div class="p-sm-3 py-0 pr-3 text-sm-center text-nowrap d-sm-table-cell">
      <div class="custom-radio">
        <input type="radio" :name="name"  value="averaged" :checked="value == 'averaged'" :disabled=doNotSell @input="$emit('input', $event.target.value)">
        &nbsp;<label for="allow" class="d-sm-none" :disabled=doNotSell>Average</label>
      </div>
    </div>  

  </div>
</template>

<script>
export default {
  name: "DemoRadioComponent",

   props: {
    'title': String, 
    'name': String, 
    'value': String, 
    'doNotSell': Boolean
  }
}
</script>
